<template>
  <div v-loading="loading" style="width: 100%">
    <el-dialog :close-on-click-modal="false" :visible.sync="isOpenDriverForm" :title="isNewDriver?$t('settings.driver_add_title'):$t('settings.driver_edit_title')">
      <el-form ref="driver" :model="driverForm" :rules="rules">
        <el-form-item :label="$t('settings.driver_name')" prop="name">
          <el-input v-model="driverForm.name" />
        </el-form-item>
        <div class="form-item-block">
          <div class="form-item-row">
            <el-form-item class="form-item-block-left" :label="$t('settings.driver_uniqueId')" prop="uniqueId" @change.native="resetDuplicatedKeyValidator">
              <el-input v-model="driverForm.uniqueId" />
            </el-form-item>
            <el-form-item class="form-item-block-right" :label="$t('settings.driver_uniqueid_create_label')">
              <el-button type="primary" size="small" @click="createUniqueID">
                {{ $t('settings.driver_uniqueid_create') }}
              </el-button>
            </el-form-item>
          </div>
        </div>
        <el-form-item :label="$t('settings.driver_email')" prop="email">
          <el-input v-model="driverForm.email" />
        </el-form-item>
        <el-form-item :label="$t('settings.driver_phone')" prop="phone">
          <el-input v-model="driverForm.phone" type="text" />
        </el-form-item>
        <el-form-item :label="$t('settings.driver_notes')">
          <el-input v-model="driverForm.notes" type="textarea" />
        </el-form-item>
        <el-form-item v-if="user.attributes.partnerReports">
          <el-checkbox v-model="driverForm.passenger">{{ $t('Passageiro') }}</el-checkbox>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button
          type="info"
          class="alertFormButton"
          size="small"
          @click="handleCancelDriverForm"
        >{{ $t('settings.form_cancel') }}</el-button>
        <el-button
          type="success"
          class="alertFormButton"
          size="small"
          :loading="loading"
          @click="handleSubmitDriverForm"
        >{{ $t('settings.form_save') }}</el-button>
      </div>
    </el-dialog>
    <el-table
      v-loading="downloadLoading"
      v-el-table-infinite-scroll="load"
      class="table-fixed"
      :height="tableHeight"
      :row-style="tableRowStyle"
      :header-cell-style="tableHeaderStyle"
      :data="filteredDrivers.slice(0, count)"
      @row-dblclick="handleDoubleClick"
    >
      <el-table-column
        prop="name"
        sortable
        min-width="20"
        show-overflow-tooltip
      >
        <template slot="header">
          <el-tooltip :content="$t('settings.driver_name')">
            <span class="ellipsis">{{ $t('settings.driver_name') }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('settings.driver_uniqueId')"
        prop="uniqueId"
        sortable
        min-width="30"
        show-overflow-tooltip
      >
        <template slot="header">
          <el-tooltip :content="$t('settings.driver_uniqueId')">
            <span class="ellipsis">{{ $t('settings.driver_uniqueId') }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('settings.driver_email')"
        prop="attributes.email"
        sortable
        min-width="20"
      >
        <template slot="header">
          <el-tooltip :content="$t('settings.driver_email')">
            <span class="ellipsis">{{ $t('settings.driver_email') }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('settings.driver_phone')"
        prop="attributes.phone"
        sortable
        min-width="20"
      >
        <template slot="header">
          <el-tooltip :content="$t('settings.driver_phone')">
            <span class="ellipsis">{{ $t('settings.driver_phone') }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        v-if="user.attributes.partnerReports"
        :label="$t('Passageiro')"
        prop="attributes.passenger"
        sortable
        min-width="20"
      >
        <template slot-scope="scope">
          {{ scope.row.attributes.passenger?'Sim':'Não' }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('settings.driver_notes')"
        prop="attributes.notes"
        sortable
        min-width="20"
      >
        <template slot="header">
          <el-tooltip :content="$t('settings.driver_notes')">
            <span class="ellipsis">{{ $t('settings.driver_notes') }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('Último vehículo utilizado')"
        prop="attributes.deviceId"
        min-width="30"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ findDevice(scope.row.attributes.deviceId) }}
        </template>
        <template slot="header">
          <el-tooltip :content="$t('Último vehículo utilizado')">
            <span class="ellipsis">{{ $t('Último vehículo utilizado') }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('Grupo')"
        min-width="30"
        show-overflow-tooltip
        prop="groupName"
        sortable
      >
        <template slot="header">
          <el-tooltip :content="$t('Grupo')">
            <span class="ellipsis">{{ $t('Grupo') }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column width="210" align="right" label="">
        <template slot="header">
          <el-tooltip :content="$t('settings.add')" placement="top">
            <el-button
              style="float: right;"
              class="formButton"
              size="medium"
              @click="handleAddGroup"
            ><i class="fas fa-plus"></i></el-button>
          </el-tooltip>
          <div style="float: right; padding-right: 10px">
            <el-button icon="el-icon-document" type="primary" @click="submitExcelDownload">Excel</el-button>
          </div>
          <!--div style="float: right; padding-right: 10px">
            <el-button icon="el-icon-delete" type="danger" @click="removeAll">{{ $t('Remover Todos') }}</el-button>
          </div-->
        </template>
        <template slot-scope="scope">
          <el-tooltip :content="$t('settings.delete')" placement="top">
            <el-button
              class="formButton"
              size="medium"
              type="danger"
              @click="handleDelete(scope.row)"
            ><i class="fas fa-trash-alt"></i></el-button>
          </el-tooltip>
          <el-tooltip :content="$t('settings.edit')" placement="top">
            <el-button
              size="medium"
              class="formButton"
              @click="handleEdit(scope.row)"
            ><i class="fas fa-edit"></i></el-button>
          </el-tooltip>
          <el-tooltip :content="$t('settings.createTempPassword')" placement="top">
            <el-button
              v-loading="loading && selectedDriver === scope.row"
              size="medium"
              class="formButton"
              @click="createTempPassword(scope.row)"
            ><i class="fas fa-key"></i></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total"
      :total="filteredDrivers.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import { vm } from '@/main'
import { traccar } from '@/api/traccar-api'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import { pinmeapi } from '@/api/pinme'
import { serverBus } from '@/main'
export default {
  name: 'Drivers',
  data() {
    return {
      tableHeight: 'calc(100vh - 130px)',
      count: 20,
      downloadLoading: false,
      isOpenDriverForm: false,
      isNewDriver: true,
      selectedDriver: null,
      isUniqueIdDuplicated: false,
      driverForm: {
        name: '',
        uniqueId: '',
        email: '',
        phone: '',
        notes: '',
        passenger: false
      }
    }
  },
  computed: {
    ...mapGetters(['drivers', 'search', 'user', 'loading', 'devices', 'groups']),
    filteredDrivers() {
      return this.drivers.filter(data => this.search === '' ||
        data.name.toLowerCase().includes(this.search.toLowerCase()) ||
        data.uniqueId.toLowerCase().includes(this.search.toLowerCase()) ||
        (data.attributes.email && data.attributes.email.toLowerCase().includes(this.search.toLowerCase())) ||
        (data.attributes.phone && data.attributes.phone.toLowerCase().includes(this.search.toLowerCase())) ||
        (data.attributes.notes && data.attributes.notes.toLowerCase().includes(this.search.toLowerCase())))
        .map(d => { return { ...d, groupName: this.findGroup(d.id) } })
        .sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  created() {
    serverBus.$on('driversClicked', (e) => {
      if (e.index === '2') {
        console.log(e)
        setTimeout(() => {
          this.tableHeight = 'calc(100vh - 270px)'
          setTimeout(() => {
            this.tableHeight = 'calc(100vh - 170px)'
          }, 500)
        }, 500)
      }
    })
    const isDuplicatedKey = (rule, value, callback) => {
      if (this.isUniqueIdDuplicated) {
        return callback(new Error())
      }
      callback()
    }
    this.rules = {
      name: [
        { required: true, message: this.$t('settings.name_required'), trigger: 'blur' }
      ],
      uniqueId: [
        { required: true, validator: isDuplicatedKey, message: this.$t('settings.uniqueid_duplicated'), trigger: 'blur' }
      ],
      email: [
        { type: 'email', message: this.$t('settings.email_format_invalid'), trigger: 'blur' }
      ],
      phone: [
      ]
    }
  },
  methods: {
    findDevice(deviceId) {
      const d = this.devices.find(d => d.id === deviceId)
      return d && d.name
    },
    findGroup(driverId) {
      for (const g of this.groups) {
        if (g.drivers && g.drivers.indexOf(driverId) !== -1) { return g.name }
      }
      return ''
    },
    createUniqueID() {
      this.driverForm.uniqueId = 'd' + Vue.moment.now()
    },
    async getDriverPassword(driver, password) {
      console.log('getDriverPassword')
      const Username = driver.uniqueId.replace(/ /g, '').toLocaleLowerCase()
      const { TemporaryPassword } = await pinmeapi.driverCreatePass({
        Username, parentUserId: this.user.id, password
      })
      return TemporaryPassword
    },
    async createTempPassword(driver) {
      try {
        const { value } = await this.$prompt(this.$t('Introduzca la contraseña', 'Password'), {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancelar',
          inputPattern: /^(?=.*[a-z])(?=.*[0-9]).{6,50}/,
          inputErrorMessage: 'Mínimo 6 caracteres, números y letras minúsculas',
          closeOnClickModal: false
        })
        this.selectedDriver = driver
        this.$store.commit('transient/SET_LOADING', true)
        const TemporaryPassword = await this.getDriverPassword(driver, value)
        const h = this.$createElement
        await this.$msgbox({
          message: h('p', null, [
            h('b', null, this.$t('Contraseña cambiada con éxito.')),
            h('br'),
            h('br'),
            h('span', null, `user: ${driver.uniqueId}`),
            h('br'),
            h('span', null, `password: ${TemporaryPassword}`),
            h('br'),
            h('span', null, `link: `),
            h('a', {
              attrs: {
                target: '_blank',
                href: 'https://driver.auth.eu-west-3.amazoncognito.com/login?client_id=7i8l0je74sh91v3g0rv2s1io2d&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Ffieldmap.net'
              },
              class: {
                'el-pagination__total': true
              }
            }, 'login')
          ])
        })
      } catch (e) {
        if (e !== 'cancel') {
          this.$message.error(e)
        }
      }
      this.$store.commit('transient/SET_LOADING', false)
    },
    load() {
      this.count += 20
    },
    async removeAll() {
      await this.$confirm(this.$t('Tem a certeza que pretende remover todos os conductores?'), this.$t('Remover Todos'), {
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        type: 'warning'
      })
      for (const d of this.filteredDrivers) {
        try {
          console.log('drivers3', this.drivers, d)
          await traccar.deleteDriver(d.id)
          this.driverDeleted(d.id)
        } catch (reason) {
          if (reason.response.data.startsWith('Account is readonly')) {
            this.$message({
              message: this.$t('settings.driver_delete_not_allowed'),
              type: 'warning',
              duration: 5 * 1000
            })
          } else {
            console.error('drivers', reason)
            this.$alert(reason)
          }
        }
      }
    },
    submitExcelDownload() {
      this.downloadLoading = true
      this.$confirm(this.$t('settings.drivers_create_new_password'), this.$t('settings.drivers_export_excel'), {
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No')
      }).then(() => {
        this.handleDownload(true)
      }).catch(() => {
        this.handleDownload(false)
      })
    },
    handleDownload(generateNewDriverPassword) {
      import('../../../utils/ExportExcel').then(async excel => {
        const tHeader = [
          this.$t('settings.driver_name'),
          this.$t('settings.driver_uniqueId'),
          this.$t('settings.driver_email'),
          this.$t('settings.driver_phone'),
          this.$t('settings.driver_notes'),
          this.$t('Grupo')
        ]
        if (generateNewDriverPassword) {
          tHeader.push(this.$t('User'))
          tHeader.push(this.$t('settings.user_password'))
        }
        const data = []
        for (const d of this.filteredDrivers) {
          const row = [
            d.name,
            d.uniqueId,
            d.attributes.email,
            d.attributes.phone,
            d.attributes.notes,
            d.groupName
          ]
          if (generateNewDriverPassword) {
            console.log(d)
            try {
              const driverPassword = await this.getDriverPassword(d)
              row.push(d.uniqueId)
              row.push(driverPassword)
              this.$message({
                message: d.name + ': ' + this.$t('settings.driver_password_changed'),
                type: 'success',
                duration: 2 * 1000
              })
            } catch (e) {
              console.log(e)
            }
          }
          data.push(row)
        }
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: '',
          autoWidth: false,
          bookType: 'xlsx'
        })
        this.downloadLoading = false
      })
    },
    tableRowStyle() {
      return 'font-size: 14px'
    },
    tableHeaderStyle() {
      return 'font-size: 14px'
    },
    handleAddGroup() {
      this.isNewDriver = true
      this.selectedDriver = null
      this.driverForm.name = ''
      this.driverForm.uniqueId = ''
      this.driverForm.email = ''
      this.driverForm.phone = ''
      this.driverForm.notes = ''
      this.driverForm.passenger = false
      this.isOpenDriverForm = !this.isOpenDriverForm
    },
    handleEdit(row) {
      this.isNewDriver = false
      this.selectedDriver = row

      this.driverForm.name = row.name
      this.driverForm.uniqueId = row.uniqueId.startsWith('_driver_') ? '' : row.uniqueId
      this.driverForm.email = row.attributes.email
      this.driverForm.phone = row.attributes.phone
      this.driverForm.notes = row.attributes.notes
      this.driverForm.passenger = row.attributes.passenger

      this.isOpenDriverForm = !this.isOpenDriverForm
    },
    handleDoubleClick(row) {
      this.handleEdit(row)
    },
    async handleDelete(row) {
      try {
        await this.$confirm(this.$t('settings.driver_delete_info') + row.name + '?', this.$t('settings.driver_delete_title'), {
          confirmButtonText: this.$t('settings.form_confirm'),
          cancelButtonText: this.$t('settings.form_cancel')
        })
        this.$store.commit('transient/SET_LOADING', true)
        await traccar.deleteDriver(row.id)
        this.driverDeleted(row.id)
      } catch (e) {
        if (e.response && e.response.data.startsWith('Account is readonly')) {
          this.$message({
            message: this.$t('settings.driver_delete_not_allowed'),
            type: 'warning',
            duration: 5 * 1000
          })
        } else {
          this.$alert(e.message)
        }
        this.$log.error(e)
      }
      this.$store.commit('transient/SET_LOADING', false)
    },
    driverDeleted(id) {
      const driver = vm.$store.state.user.drivers.find(g => g.id === id)
      this.$message({
        message: this.$t('settings.driver_deleted') + ': ' + driver.name,
        type: 'success',
        duration: 5 * 1000
      })
      this.$store.dispatch('user/removeDriver', driver)
    },
    handleCancelDriverForm() {
      this.resetDuplicatedKeyValidator()
      this.isOpenDriverForm = false
    },
    handleSubmitDriverForm() {
      this.isUniqueIdDuplicated = false
      this.$refs.driver.validate(async valid => {
        if (valid) {
          this.$store.commit('transient/SET_LOADING', true)
          if (this.isNewDriver) {
            const newDriver = {
              name: this.driverForm.name,
              uniqueId: this.driverForm.uniqueId,
              attributes: {
                email: this.driverForm.email,
                phone: this.driverForm.phone,
                notes: this.driverForm.notes,
                passenger: this.driverForm.passenger
              }
            }
            try {
              const { data } = await traccar.addDriver(newDriver)
              this.driverCreated(data)
            } catch (reason) {
              if (reason.response && reason.response.data.startsWith('Duplicate entry')) {
                this.isUniqueIdDuplicated = true
                this.$refs.driver.validate()
              } else if (reason.response.data.startsWith('Account is readonly')) {
                this.$message({
                  message: this.$t('settings.driver_add_not_allowed'),
                  type: 'warning',
                  duration: 5 * 1000
                })
              } else {
                Vue.$log.error(reason)
                this.$alert(reason.message)
              }
            }
          } else {
            const d = { ...this.selectedDriver }
            delete d.groupName
            d.name = this.driverForm.name
            d.uniqueId = this.driverForm.uniqueId
            d.attributes.email = this.driverForm.email
            d.attributes.phone = this.driverForm.phone
            d.attributes.notes = this.driverForm.notes
            d.attributes.passenger = this.driverForm.passenger

            traccar.updateDriver(this.selectedDriver.id, d)
              .then(() => this.driverUpdated())
              .catch(reason => {
                if (reason.response.data.startsWith('Duplicate entry')) {
                  this.isUniqueIdDuplicated = true
                  this.$refs.driver.validate()
                } else if (reason.response.data.startsWith('Account is readonly')) {
                  this.$message({
                    message: this.$t('settings.driver_edit_not_allowed'),
                    type: 'warning',
                    duration: 5 * 1000
                  })
                } else {
                  Vue.$log.error(reason)
                }
              })
            if (d.attributes.phone) {
              if (!d.attributes.phone.startsWith('+')) {
                d.attributes.phone = '+' + d.attributes.phone
              }
              await pinmeapi.setCognitoPhoneNumber({ Username: d.uniqueId, Value: d.attributes.phone })
            }
          }
          this.$store.commit('transient/SET_LOADING', false)
        }
      })
    },
    driverCreated: function(newDriver) {
      this.$message({
        type: 'success',
        message: this.$t('settings.driver_created')
      })
      this.isOpenDriverForm = false
      this.$store.dispatch('user/addDriver', newDriver)
    },
    driverUpdated: function() {
      this.selectedDriver.name = this.driverForm.name
      this.selectedDriver.uniqueId = this.driverForm.uniqueId
      this.selectedDriver.attributes.email = this.driverForm.email
      this.selectedDriver.attributes.phone = this.driverForm.phone
      this.selectedDriver.attributes.notes = this.driverForm.notes
      this.selectedDriver.attributes.passenger = this.driverForm.passenger
      this.$message({
        type: 'success',
        message: this.$t('settings.driver_updated')
      })
      this.isOpenDriverForm = false
    },
    resetDuplicatedKeyValidator() {
      this.isUniqueIdDuplicated = false
    },
    doNothing(scope) {
      /* this method is here because we need the attribute 'slot-scope = "scope"' on the template
       for search box to work, but to be able to commit the variable 'scope' it must be used*/
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../../styles/element-variables.scss';

  .el-form-item {
    margin-bottom: 5px
  }
  .form-item-block-left{
    display: table-cell;
    width: 400px;
    padding-right: 30px;
  }
  .form-item-block-right{
    display: table-cell;
    padding-top: 30px;
  }
  .form-item-block {
    width: 100%;
    display: table;
    margin-bottom: 5px
  }

  .form-item-row {
    display: table-row;
  }
  .el-pagination__total{
    color: $--color-primary
  }
  .ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
</style>
<style scoped>
  .table-fixed >>> .el-table__body-wrapper {
      height: calc(100vh - 190px) !important;
  }
</style>
