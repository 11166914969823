<template>
  <div style="width: 100%">
    <transition name="modal">
      <div v-if="isOpenUniqueIdForm">
        <div class="overlay">
          <div class="modal">
            <h2 v-if="isNewUniqueId">{{ $t('settings.rfid_add_title') }}</h2>
            <h2 v-else>{{ $t('settings.rfid_edit_title') }}</h2>
            <el-form ref="rfid" :model="uniqueIdForm">
              <el-form-item :label="$t('settings.driver_uniqueId')" prop="uniqueId">
                <el-input v-model="uniqueIdForm.uniqueId" />
              </el-form-item>
              <el-form-item :label="$t('settings.driver')">
                <el-select v-model="uniqueIdForm.driverId" filterable>
                  <el-option v-for="driver in drivers.slice().sort((a,b)=>a.name.localeCompare(b.name))" :key="driver.id" :label="driver.name" :value="driver.id" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('settings.rfid_start')">
                <el-date-picker
                  v-model="uniqueIdForm.startDate"
                  type="datetime"
                  :placeholder="$t('settings.startDate')"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('settings.rfid_end')">
                <el-date-picker
                  v-model="uniqueIdForm.endDate"
                  type="datetime"
                  :placeholder="$t('settings.startDate')"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
            <el-button
              type="info"
              class="formButton"
              size="small"
              @click="handleCancelForm"
            >{{ $t('settings.form_cancel') }}</el-button>
            <el-button
              type="success"
              class="formButton"
              size="small"
              @click="handleSubmitForm"
            >{{ $t('settings.form_save') }}</el-button>
          </div>
        </div>
      </div>
    </transition>
    <el-table
      v-el-table-infinite-scroll="load"
      height="calc(100vh - 130px)"
      :row-style="tableRowStyle"
      :header-cell-style="tableHeaderStyle"
      :data="filteredUniqueIds.slice(0, count)"
      @row-dblclick="handleDoubleClick"
    >
      <el-table-column
        :label="$t('settings.driver_uniqueId')"
        prop="uniqueId"
        sortable
        min-width="20"
      >
      </el-table-column>
      <el-table-column
        :label="$t('settings.driver')"
        prop="driverName"
        sortable
        min-width="20"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        :label="$t('settings.rfid_start')"
        :formatter="(row, column, cellValue) => new Date(cellValue).toLocaleString()"
        prop="startDate"
        sortable
        min-width="20"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        :label="$t('settings.rfid_end')"
        :formatter="(row, column, cellValue) => new Date(cellValue).toLocaleString()"
        prop="endDate"
        sortable
        min-width="20"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column width="250" align="right" label="">
        <template slot="header">
          <el-tooltip :content="$t('settings.add')" placement="top">
            <el-button
              style="float: right;"
              class="formButton"
              size="medium"
              @click="handleAdd"
            ><i class="fas fa-plus"></i></el-button>
          </el-tooltip>
          <div style="float: right; padding-right: 10px">
            <el-button :loading="downloadLoading" icon="el-icon-document" type="primary" @click="handleDownload">Excel</el-button>
          </div>
        </template>
        <template slot-scope="scope">
          <el-tooltip :content="$t('settings.delete')" placement="top">
            <el-button
              class="formButton"
              size="medium"
              type="danger"
              @click="handleDelete(scope.row)"
            ><i class="fas fa-trash-alt"></i></el-button>
          </el-tooltip>
          <el-tooltip :content="$t('settings.edit')" placement="top">
            <el-button
              size="medium"
              class="formButton"
              @click="handleEdit(scope.row)"
            ><i class="fas fa-edit"></i></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total"
      :total="filteredUniqueIds.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import { pinmeapi } from '@/api/pinme'

export default {
  name: 'Rfid',
  data() {
    return {
      count: 20,
      isOpenUniqueIdForm: false,
      isNewUniqueId: true,
      selectedUniqueId: null,
      uniqueIdForm: {
        uniqueId: '',
        startDate: new Date(),
        endDate: new Date(),
        driverId: ''
      }
    }
  },
  computed: {
    ...mapGetters(['driverUniqueIds', 'drivers', 'loading', 'search']),
    filteredUniqueIds() {
      return this.driverUniqueIds.filter(data => this.search === '' ||
        data.uniqueId.toLowerCase().includes(this.search.toLowerCase()) ||
        data.driverName.toLowerCase().includes(this.search.toLowerCase()))
        .sort((a, b) => a.uniqueId.localeCompare(b.uniqueId))
    }
  },
  async mounted() {
    this.$store.commit('transient/SET_LOADING', true)
    const data = await pinmeapi.getUniqueIds()
    this.$store.dispatch('user/setUniqueId', data)
    this.$store.commit('transient/SET_LOADING', false)
  },
  methods: {
    load() {
      this.count += 20
    },
    handleCancelForm() {
      this.isOpenUniqueIdForm = false
    },
    handleSubmitForm() {
      if (this.isNewUniqueId) {
        const newUniqueId = {
          id: new Date().getTime().toString(),
          driverId: this.uniqueIdForm.driverId,
          driverName: this.driverName(this.uniqueIdForm),
          uniqueId: this.uniqueIdForm.uniqueId,
          startDate: this.uniqueIdForm.startDate,
          endDate: this.uniqueIdForm.endDate
        }
        pinmeapi.addUniqueId(newUniqueId)
          .then(() => this.uniqueIdCreated(newUniqueId))
          .catch(reason => {
            Vue.$log.error(reason)
            this.$alert(reason)
          })
      } else {
        const d = { ...this.selectedUniqueId }
        d.driverId = this.uniqueIdForm.driverId
        d.driverName = this.driverName(this.uniqueIdForm)
        d.uniqueId = this.uniqueIdForm.uniqueId
        d.startDate = this.uniqueIdForm.startDate
        d.endDate = this.uniqueIdForm.endDate

        pinmeapi.updateUniqueId(d)
          .then(() => this.uniqueIdUpdated())
          .catch(reason => {
            Vue.$log.error(reason)
          })
      }
    },
    uniqueIdCreated(newUniqueId) {
      this.$message({
        type: 'success',
        message: this.$t('settings.driver_created')
      })
      this.isOpenUniqueIdForm = false
      this.$store.dispatch('user/addUniqueId', newUniqueId)
    },
    uniqueIdUpdated() {
      this.selectedUniqueId.driverId = this.uniqueIdForm.driverId
      this.selectedUniqueId.driverName = this.driverName(this.uniqueIdForm)
      this.selectedUniqueId.uniqueId = this.uniqueIdForm.uniqueId
      this.selectedUniqueId.startDate = this.uniqueIdForm.startDate
      this.selectedUniqueId.endDate = this.uniqueIdForm.endDate
      this.$message({
        type: 'success',
        message: this.$t('settings.driver_updated')
      })
      this.isOpenUniqueIdForm = false
    },
    tableRowStyle() {
      return 'font-size: 14px'
    },
    tableHeaderStyle() {
      return 'font-size: 14px'
    },
    handleEdit(row) {
      this.isNewUniqueId = false
      this.selectedUniqueId = row

      this.uniqueIdForm.driverId = row.driverId
      this.uniqueIdForm.uniqueId = row.uniqueId
      this.uniqueIdForm.startDate = row.startDate
      this.uniqueIdForm.endDate = row.endDate

      this.isOpenUniqueIdForm = !this.isOpenUniqueIdForm
    },
    handleDoubleClick(row) {
      this.handleEdit(row)
    },
    handleAdd() {
      this.isNewUniqueId = true
      this.selectedUniqueId = null
      this.uniqueIdForm.uniqueId = ''
      this.uniqueIdForm.startDate = new Date()
      this.uniqueIdForm.endDate = new Date()
      this.uniqueIdForm.driverId = ''
      this.isOpenUniqueIdForm = !this.isOpenUniqueIdForm
    },
    handleDelete(row) {
      this.$confirm(this.$t('settings.driver_delete_info') + this.driverName(row), this.$t('settings.driver_delete_title'), {
        confirmButtonText: this.$t('settings.form_confirm'),
        cancelButtonText: this.$t('settings.form_cancel')
      }).then(() => {
        pinmeapi.deleteUniqueId(row.id)
          .then(() => this.uniqueIdDeleted(row.id))
          .catch(reason => {
            Vue.$log.error(reason)
            this.$alert(reason)
          })
      }).catch((error) => {
        this.$log.error(error)
      })
    },
    uniqueIdDeleted(id) {
      this.$message({
        message: this.$t('settings.driver_deleted'),
        type: 'success',
        duration: 5 * 1000
      })
      const driverUniqueId = this.driverUniqueIds.find(d => d.id === id)
      this.$store.dispatch('user/removeUniqueId', driverUniqueId)
    },
    driverName(uniqueId) {
      const driver = this.drivers.find(d => d.id === uniqueId.driverId)
      return driver ? driver.name : ''
    }
  }
}
</script>
<style scoped lang="scss">
@import '../../../styles/element-variables.scss';

.el-form-item {
  margin-bottom: 5px
}
.el-button--small {
  padding: 9px 9px;
}
.formButton {
  float: right;
  margin-right: 10px;
}
.alertFormButton {
  float: right;
  margin-right: 10px;
}
.modal {
  width: 550px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px 3px;
  transition: all 0.2s ease-in;
}
.fadeIn-enter .modal,
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000094;
  z-index: 999;
  transition: opacity 0.2s ease;
}

.el-pagination__total{
  color: $--color-primary
}
</style>
