<template>
  <div style="margin: 10px; width: calc(100vw - 85px)">
    <div style="padding-bottom: 10px; padding-left: 10px">
      <el-switch
        v-if="allowSwitch"
        v-model="driverTable"
        :active-text="$t('settings.drivers')"
        inactive-text="RFID"
      >
      </el-switch>
      <el-button v-if="showDanoneDriverSettings" icon="el-icon-s-tools" type="primary" size="small" style="float: right; margin-bottom: 5px" @click="danoneDriverSettings">
        Autres configurations
      </el-button>
    </div>
    <drivers v-if="driverTable"></drivers>
    <rfid v-else></rfid>
  </div>
</template>

<script>
import Rfid from '@/views/settings/drivers/Rfid'
import Drivers from '@/views/settings/drivers/Drivers'
import { getPartnerData } from 'fleetmap-partners'

export default {
  name: 'Index',
  components: { Rfid, Drivers },
  data() {
    return {
      driverTable: true
    }
  },
  computed: {
    allowSwitch() {
      const partnerData = getPartnerData(window.location.hostname)
      return window.location.hostname === 'localhost' || partnerData.partnerId === 10 || partnerData.host === 'dev.fleetmap.io'
    },
    showDanoneDriverSettings() {
      return window.location.hostname === 'localhost' || window.location.hostname === 'moviflotte.com'
    }
  },
  methods: {
    danoneDriverSettings() {
      window.open('/reports/moviflotte/drivers/')
    }
  }
}
</script>

<style scoped>

</style>
